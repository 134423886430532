import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import AnimatedHeading from '../components/animated-heading'
import { Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from "gatsby-plugin-intl"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  const Section = styled.section`
  background-color: #050505;
  padding: 100px 0;
`

  return (
      <div>
          <title>{'ARIDE | ' + post.title}</title>
          <meta name="theme-color" content="#000" />
      
      
      <article
        className="portfolio-page"
        itemScope
        itemType="http://schema.org/Article"
      >
            <Section id="portfolio">
              <Col md={12} style={{padding: 0}}>
                <Container>
                  <AnimatedHeading text={"Work- " + parse(post.title)} />
               <div className="featured-image">
                  {featuredImage?.fluid && (
                    <Image
                      fluid={featuredImage.fluid}
                      alt={featuredImage.alt}
                      style={{ marginBottom: 50 }}
                    />
                  )}
               </div>
                <div className="content">
                {!!post.content && (
                  <section itemProp="articleBody" class="portolio-content">{parse(post.content)}</section>
                )}
                </div>
                <nav className="blog-post-nav">
                    <ul
                      style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                      }}
                    >
                      <li>
                        {previous && (
                          <Link to={previous.uri} rel="prev">
                            ← {parse(previous.title)}
                          </Link>
                        )}
                      </li>
                      <li class="home">
                          <Link to="/" rel="next">
                          <FontAwesomeIcon icon={faHome}/> <FormattedMessage id="home" />
                          </Link>
                      </li>
                      <li>
                        {next && (
                          <Link to={next.uri} rel="next">
                            {parse(next.title)} →
                          </Link>
                        )}
                      </li>
                    </ul>
                  </nav>
                </Container>
            </Col>
        </Section>       
      </article>

      
    
    </div>
  )
} 

export default BlogPostTemplate

export const pageQuery = graphql`
  query PortfolioPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPortfolio(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPortfolio(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPortfolio(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`